import React from 'react';

const Sponsors: React.FC = () => (
  <section>
    <h1>Sponsors</h1>
    <p>Details about the sponsors go here...</p>
  </section>
);

export default Sponsors;
