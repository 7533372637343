import React from 'react';

const Outreach: React.FC = () => (
  <section>
    <h1>Outreach</h1>
    <p>Details about the Outreach go here...</p>
  </section>
);

export default Outreach;
